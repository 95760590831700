import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Portal" description="Create and append a DOM node to the end of an HTML document and render a React tree into it" mdxType="SEO" />
    <h1 {...{
      "id": "portal"
    }}>{`Portal`}</h1>
    <p>{`Creates and appends a DOM node to the end of `}<inlineCode parentName="p">{`document.body`}</inlineCode>{` and renders a React tree into it. Useful for rendering a natural React element hierarchy with a different DOM hierarchy to prevent parent styles from clipping or hiding content (for popovers, dropdowns, and modals).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <div style={{ height: 50, overflow: "auto" }}>
      <div style={{ border: "solid 5px", padding: 20, marginLeft: 170 }}>
        This is the React Root where the portal is rendered. You can see it has
        clipped overflow, but the portal's styles are just fine.
      </div>
      <Portal>
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            width: 200,
            border: "solid 1px hsla(0, 0%, 0%, 0.25)",
            boxShadow: "0px 2px 10px hsla(0, 0%, 0%, 0.25)",
            padding: 20,
            background: "#f0f0f0",
            textAlign: "center",
          }}
        >
          This is in the portal
        </div>
      </Portal>
    </div>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/portal`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/portal`}</inlineCode>{`. Then import the component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/portal
# or
yarn add @reach/portal
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Portal from "@reach/portal";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "portal-1"
    }}>{`Portal`}</h3>
    <p>{`Renders content inside of a portal at the end of the DOM tree.`}</p>
    <h4 {...{
      "id": "portal-props"
    }}>{`Portal Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "portal-children"
    }}>{`Portal children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Any content you want to render inside of the portal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Portal>
  <div>Stuff goes here</div>
</Portal>
`}</code></pre>
    <h5 {...{
      "id": "portal-type"
    }}>{`Portal type`}</h5>
    <p><em parentName="p">{`Type`}</em>{`: `}<inlineCode parentName="p">{`string`}</inlineCode>{` default: `}<inlineCode parentName="p">{`reach-portal`}</inlineCode></p>
    <p>{`The DOM element type to render.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      